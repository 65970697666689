import React from "react"
import { StaticQuery, graphql, Link, useStaticQuery } from "gatsby"

import Form from "../Form/Form"
import Menu from "../Menu/menu"

import styles from "./Header.module.scss"

const Header = ({ hero }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "logo.header" }) {
        childImageSharp {
          base64: sizes(base64Width: 1500, quality: 100) {
            base64
          }
        }
      }
      header: file(name: { eq: "hero.header" }) {
        childImageSharp {
          base64: sizes(base64Width: 1500, quality: 100) {
            base64
          }
        }
      }
    }
  `)

  return (
    <header className={styles.headerWrapper}>
      <div className={styles.header}>
        <div className={styles.logoWrapper}>
          <Link to="/">
            <img
              alt={"logo Trilium"}
              src={data.logo.childImageSharp.base64.base64}
              className={styles.logo}
            />
          </Link>
        </div>

        <div className={styles.virtualClass}>
          <h3>Virtual Classrooms <span>Online!</span></h3>
        </div>
        <div className={styles.menu}>
          <Menu />
        </div>
      </div>
      {hero ? (
        <div className={styles.heroWrapper}>
          <div
            className={styles.hero}
            style={{
              backgroundImage: `url(${hero.childImageSharp.base64.base64})`,
            }}
          >
            {" "}
          </div>
          <Form />
        </div>
      ) : (
          <div className={styles.heroWrapper}>
            <div
              className={styles.hero}
              style={{
                backgroundImage: `url(${data.header.childImageSharp.base64.base64})`,
              }}
            ></div>

            <Form />
          </div>
        )}
    </header>
  )
}
export default Header
