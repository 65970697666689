import React from "react"

import Header from "../Header/Header"
import Gtag from '../Gtag/Gtag'
import FinancialAid from '../Financial-Aid/financialAid'
import FrequentlyAsked from '../Frequently-Asked/frequentlyAsked'
import Campuses from '../Campuses/Campuses'
import Footer from '../Footer/Footer'
import Testimonials from '../Testimonials/Testimonials'
import AssessmentBar from "../TakeAssesment/TakeAssessment"
import LearnMore from "../LearnMore/LearnMore"
import "./layout.scss"
import PersonalityAssessment from "../PersonalityAssessment/pa"

const Layout = ({ hero, children }) => {
  return (
    <>
      <Gtag />
      <AssessmentBar />
      <Header hero={hero} />
      <div>
        <main>{children}</main>
        <LearnMore />
        <PersonalityAssessment />
        <FinancialAid />
        <FrequentlyAsked />
        <LearnMore />
        <Testimonials />
        <Campuses />
        <Footer />
      </div>
    </>
  )
}



export default Layout
