import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const DigitalPage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Marketing, SEO, Design"
        titleOne="Digital Marketing Specialist Graduate Program"
      >
        Trillium College’s digital marketing specialist program provides
        specific knowledge for employment specifically in digital marketing.
        This graduate program is for students that have already completed a
        diploma or degree and require specific specialization knowledge and
        skills in only 18 weeks.
        <br /> This comprehensive course teaches the key digital marketing
        skills required for the modern workplace. Learn to effectively use
        Google Ads, social media marketing and search engine optimization,
        analytics, mobile marketing and so much more.
      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle={
          <b>This program covers information in the following subject areas:</b>
        }
      >
        <p>
          Digital marketing fundamentals <br />
          Social media marketing strategies
          <br />
          Search engine optimization
          <br />
          Strategic web design
          <br />
          Writing for the web
          <br />
          Marketing automation
          <br />
          Email marketing
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
        subtitle={<b>Main Duties</b>}
      >
        <p>
          A digital marketing specialist is someone who works alongside a
          company’s marketing team to identify a target market, create a brand
          image, and create and maintain a marketing campaign for the internet
          and for digital technologies. <br />
          <br />
          Marketing specialists can choose to specialize in certain areas, such
          as search engine optimization (SEO), paid search (PPC), display media,
          social media and shopping feeds. Additionally, they can have a more
          general digital marketing expertise and can still be referred to as a
          digital marketing specialist.
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        ><div className={styles.overlay} /> </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to jobbank.gc.ca, you can earn a median hourly wage of $50.00/hr."
        }
        admission={"College Diploma or University Degree"}
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "digital-marketing-specialist.parallax" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "digital-marketing-specialist" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default DigitalPage
