import { document } from "browser-monads"

function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = exdays == 0 ? "expires=0" : "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + "; " + expires
}

export function getCookie(cname) {
  var name = cname + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const getSubID = () => {
  if (document.location.toString().indexOf("?") !== -1) {
    const getKey = {}
    const query = document.location
      .toString()
      // get the query string
      .replace(/^.*?\?/, "")
      // and remove any existing hash string (thanks, @vrijdenker)
      .replace(/#.*$/, "")
      .split("&")
    for (var i = 0, l = query.length; i < l; i++) {
      var aux = decodeURIComponent(query[i]).split("=")
      getKey[aux[0]] = aux[1]
    }
    return setCookie("SubID", getKey["Sub_ID"], 30)
  } else {
    return null
  }
}

export const checkTime = () => {
  if (localStorage.getItem("lastSubmit")) {
    const a = Date.now() - localStorage.getItem("lastSubmit")
    const b = 144e5
    if (a > b) {
      localStorage.setItem("lastSubmit", Date.now())
      return true
    } else return false
  } else {
    localStorage.setItem("lastSubmit", Date.now())
    return true
  }
}
