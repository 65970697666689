import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import cx from "classnames"
import { navigate, useLocation } from "@reach/router"
import axios from "axios"

import { validate } from "../../utils/validate"
import { getCookie, getSubID, checkTime } from "../../utils/cookies"

import styles from "./Form.module.scss"
import Input from "../Input/Input"
import { window, document } from "browser-monads"
import { doc } from "prettier"

const Form = () => {
  const location = useLocation()
  getSubID()

  const avaiblePrograms = [
    {
      name: "Law Clerk",
      value: "Law Clerk",
    },
    {
      name: "Business Management",
      value: "Business Management",
    },
    {
      name: "Digital Marketing Specialist",
      value: "Digital Marketing Specialist",
    },
    {
      name: "Human Resource Management",
      value: "Human Resource Management",
    },
    {
      name: "Payroll Specialist",
      value: "Payroll Specialist",
    },
    {
      name: "Supply Chain Management & Logistics",
      value: "Supply Chain Management & Logistics",
    },
    {
      name: "Video Game Designer",
      value: "Video Game Designer",
    },
    {
      name: "Medical Office Assistant",
      value: "Medical Office Assistant",
    },
    {
      name: "Law And Security",
      value: "Law & Security",
    },
    {
      name: "Community And Developmental Service Worker",
      value: "Community & Developmental Service Worker",
    },
  ]

  useEffect(() => {
    const currentPathName = location.pathname
    let currentProgram = currentPathName
      .slice(1)
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")

    if (
      avaiblePrograms.some(program => {
        return (
          program.name === currentProgram ||
          program.name + "/" === currentProgram
        )
      })
    ) {
      const programToSet = avaiblePrograms.filter(
        program =>
          program.name === currentProgram ||
          program.name + "/" === currentProgram
      )
      setState({
        ...state,
        program: { value: programToSet[0].value, correct: true },
      })
    }
  }, [location.pathname])

  const [state, setState] = useState({
    firstName: { value: "", correct: true },
    lastName: { value: "", correct: true },
    email: { value: "", correct: true },
    phone: { value: "", correct: true },
    postalCode: { value: "", correct: true },
    program: { value: "", correct: true },
  })

  const [loader, setLoader] = useState(styles.loader)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleSelectChange = e => {
    setState({
      ...state,
      [e.currentTarget.name]: { value: e.currentTarget.value, correct: true },
    })
  }

  const handleInputChange = e => {
    const newValue = e.currentTarget.value

    const validatedFields = validate({
      [e.currentTarget.name]: { value: newValue, correct: true },
    })

    setState({
      ...state,
      [e.currentTarget.name]: { value: newValue, correct: validatedFields },
    })
  }

  const triggerGtag = async () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "AW-753918068")

    gtag('event', 'conversion', {'send_to': 'AW-753918068/Fhe8CJP30J0BEPTAv-cC'});
  }

  const handleSubmit = async () => {
    await setDisableSubmit(true)
    setLoader(cx(styles.loader, styles.open))
    let correctForm = true
    let newState = { ...state }

    for (let formValue in state) {
      if (state[formValue].value <= 1 || state[formValue].correct === false) {
        correctForm = false

        newState[formValue].correct = false
        newState[formValue].value = state[formValue].value
      }
    }
    setState({ ...newState })

    if (correctForm) {
      const ipUrl = `https://api.educationsource.ca/cityByPotalCode.php/?postal_code=${state.postalCode.value}`

      let payload = {
        TYPE: 19,
        Test_Lead: 0,
        ccstatus: "web",
        SRC: "on-linecollege",
        Landing_Page: "landing",
        IP_Address: "192.166.202.44",
        first_name: state.firstName.value,
        last_name: state.lastName.value,
        city: "Toronto",
        province: "ON",
        primary_phone: state.phone.value.replace(/\D+/g, ""),
        email: state.email.value,
        program: state.program.value,
        Sub_ID: "",
        postal_code: state.postalCode.value,
        school_id: "trilliumonline",
        campus: "trilliumonline",
        gmKey: '3mknhmbm2134nm324'
      }

      const getIp = await axios.get(ipUrl)
      const getUserIp = await axios.get("https://api.ipify.org")

      if (getUserIp) payload.IP_Address = getUserIp.data

      if (getIp) {
        payload.province = getIp.data["province"]
          ? getIp.data["province"]
          : "On"
        payload.city = getIp.data["city_mixed_case"]
          ? getIp.data["city_mixed_case"]
          : "Toronto"
      } else{
        payload.province = "ON"
        payload.city = "Toronto"
      }
      payload.Sub_ID = getCookie("SubID")
      if (checkTime()) {
        await triggerGtag();
        fetch('https://trillium-booking.nextgm.ca/proxy', {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        })
          .then(response => {
             return response.json()}
          )
          .then(data => {
              if(document) document.cookie = `leadID=${data.lead_id}`
              if(data.booking_url){
                navigate(data.booking_url)
              }else{
                navigate("/thank-you")
              }
          });
      } else {
        navigate("/thank-you")
      }
    } else {
      setTimeout(() => {
        setLoader(styles.loader)
        setDisableSubmit(false)
      }, 400)
    }
  }


  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { name: { regex: "/check/" } }) {
            edges {
              node {
                childImageSharp {
                  fixed(width: 50, height: 50) {
                    ...GatsbyImageSharpFixed
                  }
                }
                name
              }
            }
          }
        }
      `}
      render={data => (
        <div id="myForm" className={styles.formWrapper}>
          <div className={styles.formHeadlines}>
            <h1>I WANT ANSWERS!</h1>
            <h3>FILL OUT FORM TO GET ALL YOUR ANSWERS BY EMAIL!</h3>
          </div>
          <div className={styles.list}>
            <ul>
              <li>
                <div className={styles.listItemContainer}>
                  <Img
                    style={{ marginTop: "4px" }}
                    fixed={data.allFile.edges[0].node.childImageSharp.fixed}
                  />{" "}
                  <p>FINANCIAL AID</p>
                </div>
              </li>
              <li>
                <div className={styles.listItemContainer}>
                  <Img
                    style={{ marginTop: "4px" }}
                    fixed={data.allFile.edges[0].node.childImageSharp.fixed}
                  />{" "}
                  <p>CLASS SCHEDULE</p>
                </div>
              </li>
              <li>
                <div className={styles.listItemContainer}>
                  <Img
                    style={{ marginTop: "4px" }}
                    fixed={data.allFile.edges[0].node.childImageSharp.fixed}
                  />
                  <p>CAREER INFO</p>
                </div>
              </li>
              <li>
                <div className={styles.listItemContainer}>
                  <Img
                    style={{ marginTop: "4px" }}
                    fixed={data.allFile.edges[0].node.childImageSharp.fixed}
                  />{" "}
                  <p>JOB PLACEMENT</p>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.selectWrapper}>
            <select
              name="program"
              className={
                state.program.correct === true
                  ? styles.selectProgram
                  : cx(styles.selectProgram, styles.errorMessage)
              }
              onChange={handleSelectChange}
              value={state.program.value}
            >
              <option value="">Select Program</option>
              {avaiblePrograms.map(program => (
                <option key={program.name} value={program.value}>
                  {program.name}
                </option>
              ))}
            </select>
            <div
              className={
                state.program.correct === true
                  ? styles.errorOff
                  : cx(styles.errorMessage, styles.errorOff)
              }
            >
              {" "}
              Please select program{" "}
            </div>
          </div>
          <div className={styles.inputsWrapper}>
            <Input
              handler={handleInputChange}
              type="text"
              holder="First Name"
              name="firstName"
              correct={state.firstName.correct}
              error="Incorrect name"
            />
            <Input
              handler={handleInputChange}
              type="text"
              name="lastName"
              holder="Last Name"
              correct={state.lastName.correct}
              error="Incorrect last name"
            />
            <Input
              handler={handleInputChange}
              type="email"
              name="email"
              holder="Email"
              correct={state.email.correct}
              error="Incorrect Email"
            />
            <Input
              handler={handleInputChange}
              type="tel"
              name="phone"
              holder="Phone"
              correct={state.phone.correct}
              error="Incorrect Phone"
            />
          </div>
          <div className={styles.longInput}>
            <Input
              handler={handleInputChange}
              type="text"
              name="postalCode"
              holder="Postal Code"
              correct={state.postalCode.correct}
              error="Incorrect Postal Code"
            />
          </div>

          <div className={styles.textWrapper}>
            Please send me more information right away using my information
            above!
            <br /> I mat opt-out of electonic communication at anytime.{" "}
            <span className={styles.openModal}>
              read terms.
              <div className={styles.modal}>
                <h2>TERMS OF CONSENT</h2>
                <p>
                  By checking this box and submitting this information form, you
                  give <b>Trillium College</b> express consent to contact you at
                  the phone number(s) provided for the purposes of servicing
                  this inquiry, contacting you in the future, and removal of
                  this number(s) from <b>Trillium College</b> Do Not Call list.
                  <br />
                  <br /> You also agree to receive text message and/or email
                  correspondence and/or post correspondence related directly to
                  this education inquiry.
                  <br />
                  <br />
                  Standard message and data rates apply. Your contact details
                  are never sold, leased or shared beyond the scope of this
                  direct inquiry. <br />
                  <br /> We comply strictly with CAN-SPAM and COPPA to guard
                  your privacy and protection. You may opt-out at any time in
                  the future. We are here only to serve you in your search for
                  quality education.
                </p>
              </div>
            </span>
          </div>
          <div className={cx(styles.buttonWrapper, 'mover')}>
            <button
              disabled={disableSubmit}
              onClick={handleSubmit}
              className={styles.getInfoNow}
            >
              <div className={loader} />
              &nbsp;GET INFO NOW!
            </button>
          </div>
        </div>
      )}
    />
  )
}

export default Form
