import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/Footer/Footer"
import Gtag from "../components/Gtag/Gtag"
import AssessmentBar from "../components/TakeAssesment/TakeAssessment"
import PersonalityAssessment from "../components/PersonalityAssessment/pa"

import styles from "./thank-you.module.scss"

const ThankYouPage = ({ data: { thankYou, logo } }) => {
  const [counter, setCounter] = useState(5)

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    if (counter === 0) window.location.replace("https://www.trilliumcollege.ca/")
    return () => clearInterval(timer)
  }, [counter])

  return (
    <>
      <AssessmentBar />
      <div className={styles.pageWrapper}>
        <Gtag />
        <div className={styles.contentWrapper}>
          <div className={styles.logoWrapper}>
            <Link to="/">
              <img
                src={logo.childImageSharp.base64.base64}
                className={styles.logo}
              />
            </Link>
          </div>
          <div className={styles.heroWrapper}>
            <div className={styles.imageWrapper}>
              <Img fluid={thankYou.childImageSharp.fluid} />
            </div>
          </div>
          <div className={styles.descriptionWrapper}>
            <div className={styles.thankYou}>
              <h1> Thank You</h1>
              <h3>
                {" "}
                Our agent will contact you shortly regarding your program of
                choice.
              </h3>
            </div>
            <div className={styles.redirect}>
              <p>Visit Trillium College Main Website in {counter}</p>
            </div>
          </div>
        </div>
        <PersonalityAssessment />
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </>
  )
}
export const query = graphql`
  {
    logo: file(name: { eq: "logo.header" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
    }
    thankYou: file(name: { eq: "thankyou.header" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ThankYouPage
