import React, { useState, useEffect } from "react"
import styles from "./take_assessment.module.scss"
import cx from "classnames"

const AssessmentBar = () => {

  const [assessmentLink, setAssessmentLink] = useState('https://personalityassessment.ca/surveys/13/67f133/show');

  useEffect(() => {
    const isLeadID = getCookie('leadID');
    if(isLeadID && isLeadID.length){
        setAssessmentLink(prev => prev + `?leadID=${isLeadID}&start=2`)
    }
  }, [])

  return (
    <div className={styles.take_assessment_bar}>
      <div className={styles.content}>
        <div className={styles.content__left}>
          <p>Do You Have the Right Personality&nbsp;for&nbsp;College?</p>
        </div>
        <div className={cx(styles.content__right, "mover")}>
          <a href={assessmentLink} target="_blank">
            Take Assessment!
          </a>
        </div>
      </div>
    </div>
  )
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default AssessmentBar
