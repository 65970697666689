export const Programs = [
    {
        img: 'business.ico',
        title: 'Business management',
        content: 'Trillium College’s Business Management diploma program is taught by professionals and entrepreneurs. This program is made up of courses for business, designed to provide the knowledge and practical skills necessary for a successful career. A range of business environments are explored that will expose you to the variety of career paths available as a business graduate. The courses for business management in this program cover bookkeeping and financial accounting, marketing, management, business law, and much more.'
    },
    {
        img: 'law.ico',
        title: 'law clerk',
        content: 'This program is designed to train students to become Law Clerks to assist lawyers in private law offices and in legal departments of government and industry. Students acquire an understanding of the general principles of law and receive specific training in the skills required to work in these environments. The legal profession offers a unique mix of drama and regulation that facilitates a career of new challenges and learning.'
    },
    {
        img: 'supply.ico',
        title: `supply chain management & logistic`,
        content: 'Supply Chain Management & Logistics plays a major role in almost every industry around the world. A supply chain involves multiple facets such as operations and procurement that keep a company running smoothly, while logistics focuses on the coordination and movement of goods. By completing this program, Trillium College graduates will have the key knowledge required to contribute to this non-stop industry.'
    },
    {
        img: 'digital.ico',
        title: 'digital marketing specialist',
        content: 'Trillium College’s digital marketing specialist program provides specific knowledge for employment specifically in digital marketing. This comprehensive course teaches the key digital marketing skills required for the modern workplace. Learn to effectively use Google Ads, social media marketing and search engine optimization, analytics, mobile marketing and so much more.'
    },
    {
        img: 'human.ico',
        title: 'human resource management',
        content: 'The purpose of Human Resource Management is to maximize the productivity of an organization by optimizing the effectiveness of its employees. HR Managers are engaged not only in securing and developing the talents of individual workers, but also in implementing programs that enhance communication and cooperation needed to nurture the development of an organization.'
    },
    {
        img: 'game.ico',
        title: 'video game designer',
        content: 'Our Video Game Designer program stands out because it puts a heavy focus on the artistic and transmedia skills required in this highly creative industry. We will show you how to take your graphics further with our courses in concept art, 3D modelling, level and ultra-realistic environmental asset design, the Adobe suite of software and much more. Also, this course will teach you the computer programming and problem-solving skills.'
    },
    {
        img: 'payroll.ico',
        title: 'Payroll Specialist',
        content: 'Trillium College’s Payroll Specialist graduate diploma program is perfect for anyone that already possesses a post-secondary diploma or degree or for someone that is working in the field and would like to specialize in payroll to apply for a new position at work. The specialization provides focus on accounting principles and practices specific to the Canadian marketplace. Upon completion, you will be prepared to become a nationally recognized Payroll Compliance Practitioner (PCP) with the Canadian Payroll Association (CPA).'
    },
]