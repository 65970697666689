import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const GamePage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="GET THE SKILLS TO BE A Video Game Designer PROFESSIONAL"
        titleOne="Video Game Designer Diploma Program"
      >
        Think playing video games is fun? Just wait until you start making them!
        Trillium College’s Video Game Designer diploma program gives you the all
        the training and skills you need to kick off an exciting career in the
        video game design industry.
        <br /> Why choose Trillium College for this program? Our Video Game
        Designer program stands out because it puts a heavy focus on the
        artistic and transmedia skills required in this highly creative
        industry. We will show you how to take your graphics further with our
        courses in concept art, 3D modelling, level and ultra-realistic
        environmental asset design, the Adobe suite of software and much more.
        Also, this course will teach you the computer programming and
        problem-solving skills this industry requires.
      </AboutProgram>
      <ProgramDescription
        key="Are of Focus"
        title="Areas of Focus"
        subtitle="Interested in the nitty gritty of what we cover? This academic program covers information in the following subject areas:"
        endContent="In conjunction with your in-class activities, you will complete a demo reel of your projects showcasing your accomplishments in the program."
      >
        <p>
          Game Analysis and Technical Design
          <br />
          Storyboarding
          <br />
          Level and Asset Design
          <br />
          Concept Art and Animation
          <br />
          Character Modelling using 3DS Max / Maya
          <br />
          Environment Modelling
          <br />
          Unity3D
          <br />
          HTML5
          <br />
          C++ Programming for Game Development
          <br />
          Unreal Engine
          <br />
          Scripting in Video Games
          <br />
          Mobile Game Design
        </p>
      </ProgramDescription>
      <ProgramDescription
        key="Employment Opportunities"
        title="Employment Opportunities"
        subtitle={
          <>
            As a graduate of Trillium College’s Video Game Designer program, you
            may find exciting work as an environment modeller, animator, level
            designer, mission scripter, game designer, technical artist, QA
            engineer, web designer, visual effects producer, writer or story
            boarder.
            <br />
            <b>
              Upon successfully graduating from the program and earning their
              Video Game Designer diploma, students will qualify to apply for a
              number of positions, including:
            </b>
          </>
        }
      >
        <p>
          Environment Modeler <br />
          Animator
          <br />
          Level Designer
          <br />
          Mission Scripter
          <br />
          Game Designer
          <br />
          Technical Artist
          <br />
          QA Engineer
          <br />
          Visual Effects Producer
          <br />
          Writer
          <br />
          Story Boarder
          <br />
          Web Designer
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        />
      </div>
      <BackgroundImage
        fluid={parallax.childImageSharp.fluid}
        className={styles.programPageParralax}
        style={{
          backgroundAttachment: "fixed",
          backgroundPosition: "top",
          backgroundSize: "cover",
        }}
      >
        <div className={styles.overlay} />{" "}
      </BackgroundImage>
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "game.parallax" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "game" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default GamePage
