import React from "react"
import styles from "./financialAid.module.scss"

const FinancialAid = () => {
  return (
    <section className={styles.financialAid}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <h2>Financial Aid</h2>
          <p>
            At Trillium College, our Student Finance department is available to
            discuss all of your options and answer any of your questions. Call
            us NOW! We can help you determine a plan that is best for you. We
            are here to help you make a confident decision about investing in
            yourself and your future.
          </p>
        </div>
        <div className={styles.content}>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Ontario Student Assistance Program (OSAP)</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                Financial assistance may be available for those who qualify.
                Please feel free to contact our Trillium College Student Finance
                Advisors for more information and details regarding
                documentation required.
              </p>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Key Performance Indicator and Loan Default Rates</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                As a Trillium College graduate, you will be contacted in a few
                months’ time regarding your participation in the Key Performance
                Indicator (KPI) Graduate Satisfaction Survey.
              </p>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Out of Province Student Loans</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                Students from provinces outside of Ontario may also qualify for
                funding from the last province in which they resided for 12
                consecutive months. For a list of provincial offices, please
                visit canlearn.ca website.
              </p>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Employment Ontario</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                Second Careers is administered by the Ontario Ministry of
                Advanced Education and Skills Development (ASED) which helps
                laid-off workers re- train for a new career. If you were laid
                off any time after January 1, 2005 you are eligible to apply;
                including those who have taken an interim job to make ends meet.
                If you qualify, funding is available to support the cost of
                tuition, books, daycare, transportation and a basic living
                allowance.
              </p>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Lifelong Learning Plan (RRSP)</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                The Lifelong Leaning Plan (LLP) allows you to withdraw money
                from your RRSPs to finance your education or that of your spouse
                or common-law partner. You can withdraw up to $10,000 per year,
                up to a total maximum of $20,000.
              </p>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Registered Education Savings Plans</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                A student who is named under a family’s Registered Education
                Savings Plan may use these funds to cover the costs of education
                at an eligible post-secondary institution such as Trillium
                College.
              </p>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Workplace Safety and Insurance Board (WSIB)</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                If you are a WSIB recipient or a surviving spouse, WSIB has
                funding resources for labour market re-entry. Speak to your case
                manager about re-training, which will help prepare you to get
                back into the work force.
              </p>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.cellHead}>
              <h3>Aboriginal/First Nations</h3>
            </div>
            <div className={styles.cellBody}>
              <p>
                If you are a registered Status Indian or Inuit (residing on or
                off the reserve) and plan on going back to school, you may be
                eligible for funding for your post-secondary studies if you
                register with an eligible educational institution such as
                Trillium College.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FinancialAid
