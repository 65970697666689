import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./About.module.scss"

const About = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allFile(
            filter: { absolutePath: { regex: "/assets/woman.about.jpg/" } }
          ) {
            edges {
              node {
                childImageSharp {
                  fixed(width: 236, height: 368) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section className={styles.sectionWrapper}>
          <div className={styles.aboutWrapper}>
            <h3>Study to earn a diploma or certificate from&nbsp;home.</h3>
          </div>
          <div className={styles.aboutContent}>
            <div className={styles.imageWrapper}>
              <Img fixed={data.allFile.edges[0].node.childImageSharp.fixed} />
            </div>
            <div className={styles.contentWrapper}>
              <h3>about trillium college</h3>
              <p>
                Trillium College strives to create knowledge, to open the minds
                of students and to enable students to take best advantage of
                their educational opportunities.Our career schools with
                locations throughout Ontario are comprised of individuals
                committed to our mission and values, and the application for the
                highest professional practices and career training standards. We
                will provide quality education to our students by understanding
                their individual needs, by recognizing and defining our roles
                and responsibilities, by empowering and requiring accountability
                of our staff and students in our career schools, and by
                measuring our effectiveness. We will create and maintain a
                flexible and supportive educational environment where everyone
                can achieve their full potential, freely express themselves,
                participate in team based decisions, recognize and celebrate
                achievements, acknowledge shortcomings, and welcome change as
                means for continual improvement.
              </p>
            </div>
          </div>
        </section>
      )}
    />
  )
}

export default About
