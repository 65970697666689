import React from "react"
import styles from "./aboutProgram.module.scss"

const AboutProgram = ({titleOne, titleTwo, children}) => {

  return (
    <section className={styles.sectionWrapper}>
      <div className={styles.aboutWrapper}>
        <h3>{titleOne}</h3>
      </div>
      <div className={styles.aboutContent}>
        <div className={styles.contentWrapper}>
          <h3>{titleTwo}</h3>
          <p>
            {children}
          </p>
        </div>
      </div>
    </section>
  )
}


export default AboutProgram