import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const CommunityDevelopmentalServiceWorkerPage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Become a Community & Developmental Service Worker Specialist"
        titleOne="Community & Developmental Service Worker Program"
      >
        Trillium College’s Community & Developmental Service Worker (CDSW) diploma program gives you the knowledge, practical skills and confidence to offer support as a community service worker. A community & developmental service worker can work along side a social worker who assists people of all ages with mental and physical disabilities or who face challenges with mental health and / or addictions. A service worker is trained to help those who struggle with a variety of social challenges.
        This program gives you the skills needed to work as part of a social support team that works with individuals or community groups by facilitating appropriate social, physical and mental health services. You will develop skills as a community & developmental service worker in client assessment, client education, referral procedures, and effective monitoring of client treatments.

      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="This academic program covers information in the following subject areas:"
      >
        <p>
          Becoming a Helping Professional in Human Services<br />
          Utilizing Community Resources<br />
          Understanding Issues of Diversity<br />
          Working with High-risk Populations<br />
          Working with Addiction and Mental Health Issues<br />
          Structure, Function and Common Disorders of the Human Body<br />
          Developmental Disabilities<br />
          Fundamentals of Psychology and Sociology<br />
          Interviewing and Counselling Strategies<br />
          Dynamics within Families<br />
          Communication Techniques and Processes<br />
          Non-violent Crisis Intervention<br />
          First Aid and CPR<br />
          Ethics and Professionalism<br />
          Concepts in Behavioral Management<br />
          Pharmacology<br />
          Legal Awareness, Reporting and Documentation<br />
        </p>

      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
      >
      <p>As a graduate of Trillium College’s Community & Developmental Service Worker (CDSW) diploma program, the opportunities you can pursue span nearly all aspects of human services. You may find employment as a life skills instructor, community development worker, community service worker, crisis intervention worker, developmental service worker, drop-in center worker, family service worker, group home worker, mental health worker, Aboriginal outreach worker, veteran services officer, welfare and compensation officer, addictions worker, women’s shelter supervisor, rehabilitation worker. Potential work settings for a community service worker may include community residences, private homes, recreation programs, schools, hospitals, social services, and government agencies.</p>

      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay} />{" "}
        </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to updated information on Indeed.ca"
        }
        admission={`
        Grade 12 diploma or equivalent, or mature student status (18 years of age)`}
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "payroll-specialist.parallax" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "Community-Developmental-Service-Worker" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default CommunityDevelopmentalServiceWorkerPage
