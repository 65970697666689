import React, { useState } from "react"
import { Link } from "gatsby"
import cx from "classnames"

import styles from "./menu.module.scss"

const Menu = () => {
  const [isOpenDesktop, setIsOpenDesktop] = useState(false)

  const handleClickDesktopMenu = () => {
    setIsOpenDesktop(!isOpenDesktop)
  }


  return (
    <div className={styles.menu}>
      <div
        className={
          isOpenDesktop
            ? cx(styles.desktopWrapper, styles.openDesktop)
            : styles.desktopWrapper
        }
      >
        <div onClick={handleClickDesktopMenu} className={styles.text}>
          <p>Available Programs &nbsp;<span className={styles.arrow}>&#8250;</span></p>
        </div>
        <div className={styles.burger}>
          <button
            onClick={handleClickDesktopMenu}
            className={styles.hamburger}
            type="button"
          >
            <div className={styles.hamburgerBox}>
              <div
                className={
                  isOpenDesktop
                    ? cx(styles.hamburgerBarOne, styles.change)
                    : styles.hamburgerBarOne
                }
              ></div>
              <div
                className={
                  isOpenDesktop
                    ? cx(styles.hamburgerBarTwo, styles.change)
                    : styles.hamburgerBarTwo
                }
              ></div>
              <div
                className={
                  isOpenDesktop
                    ? cx(styles.hamburgerBarThree, styles.change)
                    : styles.hamburgerBarThree
                }
              ></div>
            </div>
          </button>
        </div>
        <div className={styles.desktopMenu}>
          <ul>
            <li key={Math.random()}>
              <Link onClick={handleClickDesktopMenu} to="/business-management">
                BUSINESS MANAGEMENT
              </Link>
            </li>
            <li key={Math.random()}>
              <Link onClick={handleClickDesktopMenu} to="/law-clerk">
                LAW CLERK
              </Link>
            </li>
            <li key={Math.random()}>
              <Link
                onClick={handleClickDesktopMenu}
                to="/supply-chain-management-logistics"
              >
                SUPPLY CHAIN MANAGEMENT & LOGISTICS
              </Link>
            </li>
            <li key={Math.random()}>
              <Link
                onClick={handleClickDesktopMenu}
                to="/digital-marketing-specialist"
              >
                DIGITAL MARKETING SPECIALIST
              </Link>
            </li>
            <li key={Math.random()}>
              <Link
                onClick={handleClickDesktopMenu}
                to="/human-resource-management"
              >
                HUMAN RESOURCE MANAGEMENT
              </Link>
            </li>
            <li key={Math.random()}>
              <Link onClick={handleClickDesktopMenu} to="/video-game-designer">
                VIDEO GAME DESIGNER
              </Link>
            </li>
            <li key={Math.random()}>
              <Link onClick={handleClickDesktopMenu} to="/payroll-specialist">
                PAYROLL SPECIALIST
              </Link>
            </li>
            <li key={Math.random()}>
              <Link onClick={handleClickDesktopMenu} to="/medical-office-assistant">
                MEDICAL OFFICE ASSISTANT
              </Link>
            </li>
            <li key={Math.random()}>
              <Link onClick={handleClickDesktopMenu} to="/law-and-security">
                LAW & SECURITY
              </Link>
            </li>
            <li key={Math.random()}>
              <Link onClick={handleClickDesktopMenu} to="/community-and-developmental-service-worker">
                COMMUNITY & DEVELOPMENTAL
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Menu
