import React from "react"
import { withPrefix } from "gatsby"

import terms from "../../static/termsandconditions.pdf"
import policy from "../../static/privacypolicy.pdf"

import styles from "./Footer.module.scss"

const Footer = () => {
  return (
    <section className={styles.footer}>
      <div className={styles.contentWrapper}>
        <div className={styles.links}>
          <a rel="noopener noreferrer" target="_blank" href={withPrefix(terms)}>
            Terms & Conditions
          </a>
          <a target="_blank" href={withPrefix(policy)}>
            Privacy Policy
          </a>
        </div>
        <div className={styles.tm}>&#169; Trillium College 2020</div>
      </div>
    </section>
  )
}

export default Footer
