import React from "react";
import cx from 'classnames'

import styles from './learn_more.module.scss';

const LearnMore = () => {
    return(
        <section className={styles.learn_more}>
            <a href="#myForm" className={cx(styles.learn_more__btn, 'mover')}>Learn More!</a>
        </section>
    )
}

export default  LearnMore