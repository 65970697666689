import React, { useState } from "react"
import styles from "./questionCard.module.scss"
import cx from "classnames"

const QuestionCard = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.askWrapper} onClick={handleOpen}>
        <h3>{question}</h3>
      </div>
      <div
        className={
          isOpen ? cx(styles.answerWrapper, styles.open) : styles.answerWrapper
        }
      >
        {answer}
      </div>
    </div>
  )
}

export default QuestionCard
