import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const LawClerkPage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Client Relations, Critical Thinking, Law"
        titleOne="Law Clerk Diploma Program"
      >
        Trillium College’s Law Clerk program give student the specialization
        they need to excel in their field. This program is designed to train
        students to become Law Clerks to assist lawyers in private law offices
        and in legal departments of government and industry. Students acquire an
        understanding of the general principles of law and receive specific
        training in the skills required to work in these environments. The legal
        profession offers a unique mix of drama and regulation that facilitates
        a career of new challenges and learning. Whether you participate in a
        divorce negotiation, a bail hearing, the sale of a house, or a criminal
        case, Trillium College’s Law Clerk diploma program gives you the
        foundation to excel.
      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="Students will master skills and knowledge in the following areas:"
      >
        <p>
          Accounting for Businesses
          <br />
          MS Office Applications <br />
          Client Relations and Diversity in the Workplace
          <br />
          Critical Thinking, Problem Solving & Decision Making
          <br />
          Introduction to Law <br />
          Torts and Contracts
          <br />
          Legal Research Writing and Dicta Typing
          <br />
          Legal Office Procedures
          <br />
          Family Law
          <br />
          Wills, Estates and Real Estate Law
          <br />
          Corporate Law and Civil Litigation
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
        subtitle="Graduates may find employment with law firms and in legal departments of corporations and government agencies."
      >
        <p>
          &#183; Prepare and key in correspondence and legal documents, such as
          deeds, wills, affidavits and briefs, from handwritten copy, shorthand
          and machine dictation using computers
          <br />
          &#183; Review and proofread documents and correspondence to ensure
          compliance with legal procedures and grammatical usage
          <br />
          &#183; Schedule appointments, meetings and conferences for employer
          <br />
          &#183; Set up and maintain filing systems, utilizing knowledge of
          legal records and procedures and frequently controlling confidential
          materials and documents
          <br />
          &#183; Open and distribute regular and electronic incoming mail and
          other material and co-ordinate the flow of information internally and
          with other departments or organizations
          <br />
          &#183; Determine and establish office procedures and routines
          <br />
          &#183; May supervise and train other staff in procedures and in the
          use of current software
          <br />
          &#183; May attend court, meetings or conferences to take notes,
          minutes and dictation
          <br />
          &#183; May perform other general office work as required including
          preparing financial statements.
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay} />{" "}
        </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to jobbank.gc.ca, the median annual salary for a law clerk is $52k."
        }
        admission={
          <>
            Ontario Secondary School Diploma or
            <br />
            Mature Student with passing score on the admission test
          </>
        }
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "law-clerk.parallax" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "law-clerk" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default LawClerkPage
