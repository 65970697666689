import React from "react"

import Layout from "../components/Layout/layout"
import About from '../components/About/About'
import Program from '../components/Program-Section/programSection'
import BlueDescription from '../components/Blue-Description/blueDescription'

const IndexPage = () => (
  <div>
    <Layout>
      <About />
      <Program />
      <BlueDescription page={true} />
    </Layout>
  </div>
)

export default IndexPage
