export const validate = inputs => {
  let correct = true

  for (let inputValue in inputs) {
    switch (inputValue) {
      case "firstName":
        if (
          isSameChars(inputs[inputValue].value) ||
          inputs[inputValue].value.length <= 1
        ) {
          correct = false
        }
        break
      case "lastName":
        if (
          isSameChars(inputs[inputValue].value) ||
          inputs[inputValue].value.length <= 1
        ) {
          correct = false
        }
        break
      case "phone":
        const d = inputs[inputValue].value.substring(1, 4),
          e = inputs[inputValue].value.replace("_", "")
        if (
          inputs[inputValue].value.length < 14 ||
          !d.match(
            /^(204|226|236|249|250|289|306|343|365|403|416|418|431|437|438|450|506|514|519|548|579|581|587|604|613|639|647|705|709|778|780|782|807|819|825|867|873|902|905)$/
          ) ||
          e.charAt(6).match(/^(0|1)$/) !== null
        ) {
          correct = false
        }
        break
      case "email":
        if (
          !inputs[inputValue].value.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          correct = false
        }
        break
      case "postalCode":
        const ca = new RegExp(
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
        )

        if (!ca.test(inputs[inputValue].value.toString().replace(/\W+/g, ""))) {
          correct = false
        }
    }
  }
  return correct
}

function isSameChars(a) {
  if (((a = a.toLowerCase()), a.charAt(0) === a.charAt(1))) {
    return true
  } else return false
}


 const isNumericInput = function (event) {
  const key = event.keyCode;
  return (
      (event.shiftKey === false && key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
  );
};

export const formatToPhone = function (event) {
  if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
  }
};

const isModifierKey = function (event) {
  const key = event.keyCode;
  return (
      (event.shiftKey === true && key < 32) ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 ||
              key === 67 ||
              key === 86 ||
              key === 88 ||
              key === 90))
  );
};


export const formatToPhonePlus = function (event) {
  const mask = "(###) ###-####";
  const maskRegEx = /^\([0-9]{3}\) [0-9]{3}\-[0-9]{4}/;
  const placeholders = "() -";
  const input = event.target.value
      .replace(/[^0-9]/g, "")
      .substring(0, 10); // First ten digits of input only
  var target = event.target;
  var val = target.value;
  var pos = target.selectionEnd;
  var idx = pos - 1;
  // if there is nothing to do just leave
  if (isModifierKey(event)) {
      return;
  }
  // If a number was added at the location of a placeholder
  if (placeholders.includes(mask[idx])) {
      //move char: remove from string, and place it after
      val = val.split(""); // convert to array
      val.splice(idx, 1); // remove the number char
      val.splice(idx, 0, mask[idx], event.key); // insert mask char
      target.value = val.join("");
      target.selectionEnd = pos + 1;
      target.selectionStart = pos + 1;
  }
  if (val.length > mask.length) {
      // in the event the string is too long
      val = val.toString().replace(/\D/g, "");
      val = val.substring(0, 10);
      target.value =
          "(" +
          val.substring(0, 3) +
          ") " +
          val.substring(3, 6) +
          "-" +
          val.substring(6, 10);
      target.selectionEnd = pos;
      target.selectionStart = pos;
  } else if (input.length >= 10) {
      target.value =
          "(" +
          input.substring(0, 3) +
          ") " +
          input.substring(3, 6) +
          "-" +
          input.substring(6, 10);
  }
};
