import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const LawSecurityPage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Become a Law & Security Specialist"
        titleOne="Law & Security Program"
      >
    Trillium College’s Law & Security diploma program provides theoretical and hands-on training and gives you the knowledge, skills and industry contacts needed for successful employment in the policing and public safety fields. Our program is recognized by the Ministry of Community Safety and Correctional Services (MCSCS) and taught by professionals from the public safety arena.
    Leading to one of the best community service career paths in Canada, the Law & Security program provides individuals with the fundamental knowledge and skills needed for successful employment in the field.

      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="This academic program covers information in the following subject areas:"
      >
        <p>Fundamentals of Psychology<br />
          Criminology and the Criminal Justice System<br />
          Community Policing<br />
          Understanding Issues of Diversity<br />
          Interviewing, Investigation and Evidence<br />
          Non-violent Crisis Intervention<br />
          Working with High-risk Populations<br />
          Working with Addiction and Mental Health Issues<br />
          Utilizing Community Resources<br />
          Provincial Offences, Federal Statutes and the Criminal Code<br />
          Communication Techniques and Processes<br />
          Ethics and Professionalism<br />
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
      >
<p>As a graduate of Trillium College’s Law & Security program, you will be prepared for work in a variety of professional law enforcement areas, including municipal by-law and civil enforcement, airport security guard, house detective, private investigator, military police, railway police, police services, public and private security companies, and investigation firms. This diploma also gives you the opportunity to pursue training to work with the railway police, military police, Royal Canadian Mounted Police (RCMP), corrections, customs and border services.</p>
<p>Graduates with a Law & Security diploma may be eligible to work with Ontario Police Services, as Public Security Guards, with Private Security and Investigation Firms, Federal Enforcement Agencies (such as RCMP or Customs Officers), Conservation Services, Railway Police, or Military Police. Potential careers are also available in By-Law Enforcement and Civil Enforcement.</p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay} />{" "}
        </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to updated information on Indeed.ca"
        }
        admission={"Ontario Secondary School Diploma or Mature Student Status"}
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "payroll-specialist.parallax" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "Law-Security" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default LawSecurityPage
