import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const GamePage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Logistics, Supply, Management"
        titleOne="Supply Chain Management & Logistics Diploma Program"
      >
        Supply Chain Management & Logistics plays a major role in almost every
        industry around the world. A supply chain involves multiple facets such
        as operations and procurement that keep a company running smoothly,
        while logistics focuses on the coordination and movement of goods. By
        completing this program, Trillium College graduates will have the key
        knowledge required to contribute to this non-stop industry.
        <br /> In today’s competitive environment, managing the supply chain and
        effectively controlling supply chain costs is critical for business.
        This has created a demand for employees with current skills and
        understanding on how to effectively manage the supply chain using
        up-to-date tools, processes and technology.
      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="This program covers information in the following subject areas:"
      >
        <p>
          Accounting for Businesses <br />
          MS Office Applications
          <br />
          Customer Service & Sales
          <br />
          Logistics & Transportation
          <br />
          Purchasing, Inventory Control, Security, Compliance and Loss
          Prevention
          <br />
          Introduction to Contract Law and Administration
          <br />
          Operations Management
          <br />
          Negotiations and Supplier Management
          <br />
          International Transportation and Trade
          <br />
          Essentials of Freight Forwarding
          <br />
          Customs Issues and Procedures
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
        subtitle="Main Duties"
      >
        <p>
          Review requisition orders for accuracy and verify that materials,
          equipment and stock are not available from current inventories.
          <br />
          Source and obtain price quotations from catalogues and suppliers and
          prepare purchase orders.
          <br />
          Calculate cost of orders and charge or forward invoices to appropriate
          accounts
          <br />
          Contact suppliers to schedule deliveries and to resolve shortages,
          missed deliveries and other problems.
          <br />
          Prepare and maintain purchasing files, reports and price lists.
          <br />
          Monitor inventory levels as materials, equipment and stock are issued,
          transferred within an establishment or sold to the public using manual
          or computerized inventory systems.
          <br />
          Identify vendors of materials, equipment or supplies.
          <br />
          Evaluate cost and quality of goods or services.
          <br />
          Negotiate or oversee the negotiation of purchase contracts.
          <br />
          Participate in the development of specifications for equipment,
          products or substitute materials.
          <br />
          Review and process claims against suppliers.
          <br />
          Compile inventory reports, recording the quantity, type and value of
          materials, equipment and stock on hand, using manual or computerized
          inventory systems.
          <br />
          Prepare requisition orders to replenish materials, equipment and
          stock.
          <br />
          Maintain stock rotation and dispose of and account for outdated stock.
          <br />
          Enter data for production scheduling, stock replenishment/relocation
          and inventory adjustments.
          <br />
          Reconcile physical inventories with computer counts.
          <br />
          Plan, organize, direct, control and evaluate the purchasing activities
          of an establishment.
          <br />
          Develop purchasing policies and procedures and control purchasing
          department budget.
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay} />{" "}
        </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to payscale.com Canada, you can earn an average annual salary of $58k."
        }
        admission={
          "Ontario Secondary School (or equivalent) diploma OR mature student status (18 years of age) with a minimum Wonderlic test score of 17"
        }
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "supply-chain-management-logistics.parallax" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "supply-chain-management-logistics" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default GamePage
