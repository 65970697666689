import React, { useState, useEffect } from "react"
import styles from "./Input.module.scss"
import cx from "classnames"

import {formatToPhone, formatToPhonePlus} from '../../utils/validate'

const Input = ({ type, name, handler, holder, correct, error }) => {
  const [inputStyles, setInputStyles] = useState(styles.form_input)

  useEffect(() => {
    correct
      ? setInputStyles(styles.form_input)
      : setInputStyles(cx(styles.form_input, styles.error))
  }, [correct])

  const keyDownHandler = (e) => {
    formatToPhone(e)
  }

  const keyUpHandler = (e) => {
    formatToPhonePlus(e)
  }


  return (
    <div className={styles.inputWrapper}>
      {name === "phone" ? (
        <input
          onBlur={handler}
          onKeyDown={keyDownHandler}
          onKeyUp={keyUpHandler}
          type={type}
          name={name}
          id={name}
          placeholder={holder}
          className={inputStyles}
        />
      ) : (
        <input
          onBlur={handler}
          type={type}
          name={name}
          id={name}
          placeholder={holder}
          className={inputStyles}
        />
      )}
      <div className={cx(styles.errorMessage, inputStyles)}>{error}</div>
    </div>
  )
}

export default Input
