import React from "react"
import styles from "./blueDescription.module.scss"

const BlueDescription = ({ page, salary, admission }) => {
  return (
    <section className={styles.blueDescription}>
      {page ? (
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <h2>TRILLIUM COLLEGE IS REGISTERED AS A PRIVATE CAREER COLLEGE</h2>
          </div>
          <div className={styles.description}>
            <p>
              Trillium College is a registered Private Career College under the
              Private Career Colleges Act, 2005. Trillium College programs are
              approved as vocational programs under the Private Career Colleges
              Act, 2005. <br />
              <br />
              Trillium College is a career oriented school with campus locations
              throughout Ontario providing exceptional educational programs. Our
              goal at Trillium College is to equip you with the most up to date
              skills that are in demand by employers in your community.
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.contentWrapperProgram}>
          <div className={styles.column}>
            <div className={styles.header}>
              <h2>Salary</h2>
            </div>
            <div className={styles.description}>
              <p>{salary}</p>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.header}>
              <h2>Admission requirements</h2>
            </div>
            <div className={styles.description}>
              <p>{admission}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default BlueDescription
