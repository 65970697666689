import React from "react"

import ProgramCard from "../Program-Card/programCard"

import { Programs } from "../../data/programs"
import styles from "./programSection.module.scss"

const Program = () => {
  return (
    <section className={styles.programWrapper}>
      <div className={styles.headerWrapper}>
        <h3>choose from our 7 exciting careers!</h3>
      </div>
      <div className={styles.contentWrapper}>
        {Programs.map(({ img, title, content }, idx) => (
          <ProgramCard
            key={idx}
            img={img}
            title={title}
            content={content}
          ></ProgramCard>
        ))}
      </div>
    </section>
  )
}

export default Program
