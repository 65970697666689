import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { window } from "browser-monads"

import styles from './Testimonials.module.scss';
import Carousel from "react-multi-carousel";
import "../../../node_modules/react-multi-carousel/lib/styles.css";

const Testimonials = () => {
    const [isMobile, setMobile] = useState(window.innerWidth > 900);

    const updateMedia = () => {
        setMobile(window.innerWidth > 900);
    }

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    return (
        <div className={styles.testimonialsWrapper}>
            <h1>WHAT OUR CLIENTS SAY ABOUT US:</h1>
            <Carousel responsive={responsive}
                swipeable={true}
                draggable={false}
                showDots={false}
                infinite={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5s"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customTransition="all .5s linear"
                centerMode={isMobile}

            >
                <div className={cx(styles.itemClass, styles.inner)}>
                    <div>
                        <div className={styles.user}></div>
                        <div className={styles.content}>
                            <div className={styles.author}>LJohn Bishop</div>
                            <div className={styles.google_ico}></div>
                            <ul className={styles.stars}>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <p>Trillium College truly helped me get my career on track.....it is a very good place to go to school</p>
                </div>
                <div className={cx(styles.itemClass, styles.inner)}>
                    <div>
                        <div className={styles.user}></div>
                        <div className={styles.content}>
                            <div className={styles.author}>Kristan Cannon</div>
                            <div className={styles.google_ico}></div>
                            <ul className={styles.stars}>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <p>The class sizes are smaller so it's far easier to engage with the instructors. The cost is comparable to other colleges - actually for a 2 year diploma program the cost is identical to any other college. The student finance advisor is an absolute master at carving through any OSAP issues you may have.
</p>
                </div>
                <div className={cx(styles.itemClass, styles.inner)}>
                    <div>
                        <div className={styles.user}></div>
                        <div className={styles.content}>
                            <div className={styles.author}>Vanessa Vieira</div>
                            <div className={styles.google_ico}></div>
                            <ul className={styles.stars}>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <p>This college definitely deserves more than 5 stars. The staff here are absolutely amazing, and incredibly helpful. They offer the support you need both academically and they even help with your personal problems. I made the right choice in picking this college for my future career path of being a dental assistant. Absolutely amazing! :)</p>
                </div>
                <div className={cx(styles.itemClass, styles.inner)}>
                    <div>
                        <div className={styles.user}></div>
                        <div className={styles.content}>
                            <div className={styles.author}>Nhandu Chromatus</div>
                            <div className={styles.google_ico}></div>
                            <ul className={styles.stars}>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <p>Shannon the student financial planner has been great with all my paperwork and required forms.  Always a smile, just started the PSW course and very happy with all Staff this far.</p>
                </div>
                <div className={cx(styles.itemClass, styles.inner)}>
                    <div>
                        <div className={styles.user}></div>
                        <div className={styles.content}>
                            <div className={styles.author}>Holly Chamberlain</div>
                            <div className={styles.google_ico}></div>
                            <ul className={styles.stars}>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <p>My experience with Trillium was more than AMAZING. Going back to school at 55 was a huge step to begin with...but the best step I ever took....all of the staff were amazing and my teacher was so AWESOME. The rest of the staff were right there when needed and before long they became family. Class size was good also. Because of all of these wonderful people, I was able to fulfill my dream, and am now a Personal Support Worker. I graduated December 14th with honours. So if you want an exciting and fun environment...try Trillium. Thank you to everyone that was a part of my journey. Holly L Chamberlain.</p>
                </div>
            </Carousel>
        </div>
    )
}

export default Testimonials