import React from "react"
import cx from "classnames"

import styles from "./programDescription.module.scss"

const ProgramDescription = ({
  title,
  subtitle,
  children,
  endContent,
  long,
}) => {
  return (
    <section className={styles.sectionWrapper}>
      <div
        className={
          long ? cx(styles.headerWrapper, styles.long) : styles.headerWrapper
        }
      >
        <div className={styles.header}>
          <h2>{title}</h2>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.subTitle}>
          <p>{subtitle}</p>
        </div>
        <div className={styles.content}>{children}</div>
        {endContent ? (
          <div className={styles.endContent}>{endContent}</div>
        ) : null}
      </div>
    </section>
  )
}

export default ProgramDescription
