import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const BusinessPage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Courses for Business Management"
        titleOne="Business Management Diploma Program"
      >
        Trillium College’s Business Management diploma program is taught by
        professionals and entrepreneurs. This program is made up of courses for
        business, designed to provide the knowledge and practical skills
        necessary for a successful career. A range of business environments are
        explored that will expose you to the variety of career paths available
        as a business graduate. The courses for business management in this
        program cover bookkeeping and financial accounting, marketing,
        management, business law, and much more.
        <br />
        <br /> Already have a Post Secondary Diploma or Bachelor and want to
        add-on a graduate program to specialize and maximize your income?
        Validate your offshore Degree! Check out our{" "}
        <Link to="/human-resource-management" style={{ color: "black" }}>
          Human Resource Management Graduate Program
        </Link>
        ,{" "}
        <Link to="/digital-marketing-specialist" style={{ color: "black" }}>
          Digital Marketing Specialist Graduate Programs
        </Link>{" "}
        and{" "}
        <Link to="/payroll-specialist" style={{ color: "black" }}>
          Payroll Specialist Graduate Programs.
        </Link>{" "}
        Once you have taken our Business Management Diploma you can qualify for
        our specialization graduate program.
      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="The courses for this program cover information in the following subject areas:
        "
      >
        <p>
          Business Plan Preparation
          <br />
          Small Business Management
          <br />
          Business Law
          <br />
          Human Resources
          <br />
          Marketing
          <br />
          Sales
          <br />
          Digital Marketing and Social Media
          <br />
          Word Processing and Spreadsheets
          <br />
          Bookkeeping and Financial Accounting
          <br />
          Ethics and Professionalism
          <br />
          Communication Skills
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
      // subtitle={[
      //   `As a graduate of Trillium College’s Video Game Designer program, you may find exciting work as an environment modeller, animator, level designer, mission scripter, game designer, technical artist, QA engineer, web designer, visual effects producer, writer or story boarder.`,
      //   <br />,
      //   <b>
      //     Upon successfully graduating from the program and earning their
      //     Video Game Designer diploma, students will qualify to apply for a
      //     number of positions, including:
      //   </b>,
      // ]}
      >
        <p>
          As a business graduate of this program, you will be prepared for a
          range of opportunities. Our courses for business management prepare
          you to join a family-run operation, work with a commercial or
          service-based enterprise, and/or successfully run your own business.
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
      <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        ><div className={styles.overlay} /> </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to jobbank.gc.ca an average salary for a Business Management Consultant is $34.62 per hour (NOC 1122)."
        }
        admission={
          "Grade 12 diploma or equivalent, or passing score on the Wonderlic SLE entrance exam"
        }
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "business-management.parallax" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "business-management" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default BusinessPage
