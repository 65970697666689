import React from "react"
import styles from "./campuses.module.scss"

const Campuses = () => (
  <div className={styles.campusesWrapper}>
    <div className={styles.content}>
      <div className={styles.campus}>
        <div>
          <div className={styles.campusName}>OSHAWA CAMPUS:</div>
          <div className={styles.campusAddress}>
            Oshawa Centre
            <br />
            419 King Street West Oshawa
            <br />
            ON L1J 2K5
          </div>
        </div>
      </div>
      <div className={styles.campus}>
        <div>
          <div className={styles.campusName}>ST.&nbsp;CATHARINES CAMPUS:</div>
          <div className={styles.campusAddress}>
            60 James Street
            <br />
            St. Catharines
            <br />
            ON L2R 7E7
          </div>
        </div>
      </div>
      <div className={styles.campus}>
        <div>
          <div className={styles.campusName}>KINGSTON CAMPUS:</div>
          <div className={styles.campusAddress}>
            797 Princess Street
            <br />
            Kingston
            <br />
            ON K7L 1G1
          </div>
        </div>
      </div>
      <div className={styles.campus}>
        <div>
          <div className={styles.campusName}>SUDBURY CAMPUS:</div>
          <div className={styles.campusAddress}>
            Sudbury-Elm Place,
            <br /> 40 Elm Street, Unit 248 <br />
            Sudbury ON P3C 1S8
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Campuses
