import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { window, document } from "browser-monads"

const Gtag = () => {
  let pageTitle
  if (window.location.pathname !== "/") {
    pageTitle = window.location.pathname.slice(1).split("-").join(" ")
  } else {
    pageTitle = "Trillium College"
  }

  const clarity = (c, l, a, r, i, t, y) => {
    c[a] =
      c[a] ||
      function () {
        ;(c[a].q = c[a].q || []).push(arguments)
      }

    t = l.createElement(r)
    t.async = 1
    t.src = "https://www.clarity.ms/tag/" + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-753918068"
    script.async = true
    if (
      ![...document.querySelectorAll("script")].find(
        script =>
          script.getAttribute("src") ===
          "https://www.googletagmanager.com/gtag/js?id=AW-753918068"
      )
    ) {
      document.body.appendChild(script)
      clarity(window, document, "clarity", "script", "3z7szqd102")
    }
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('set', 'linker', {
      'domains': ['personalityassessment.ca']
    });
    gtag("js", new Date())
    gtag("config", "AW-753918068")

    if (window.location.pathname === "/thank-you") {
      gtag("event", "conversion", {
        send_to: "AW-753918068/Fhe8CJP30J0BEPTAv-cC",
      })
    }
  }, [])

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  )
}
export default Gtag
