import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const PayrollPage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Become a Payroll Specialist"
        titleOne="Payroll Specialist Graduate Program"
      >
        Trillium College’s Payroll Specialist graduate diploma program is
        perfect for anyone that already possesses a post-secondary diploma or
        degree or for someone that is working in the field and would like to
        specialize in payroll to apply for a new position at work. The
        specialization provides focus on accounting principles and practices
        specific to the Canadian marketplace. Upon completion, you will be
        prepared to become a nationally recognized Payroll Compliance
        Practitioner (PCP) with the Canadian Payroll Association (CPA).
      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="This program covers information in the following subject areas:        "
      >
        <p>
          Essentials of accounting and different forms of business organizations
          <br />
          Accounting for merchandising <br />
          An understanding about the various government agencies and legislation
          that impact payroll, both from an employer and an employee’s
          standpoint
          <br />
          How to produce payroll at an employee level, keeping in compliance
          with legislation
          <br />
          Calculate and report government and third party remittances, year-end
          requirements and accounting for payroll at the compliance level for
          the organization
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
        subtitle="Main Duties"
      >
        <p>
          Maintain records of employee attendance, leave and overtime to
          calculate pay and benefit entitlements, in Canadian and other
          currencies, using manual or computerized systems
          <br />
          Prepare and verify statements of earnings for employees, indicating
          gross and net salaries and deductions such as taxes, union dues,
          garnishments and insurance and pension plans
          <br />
          Prepare employee payments and benefit payments by cheque or electronic
          transfer
          <br />
          Complete, verify and process forms and documentation for
          administration of benefits such as pension plans, leaves, share
          savings, employment and medical insurance
          <br />
          Prepare T4 statements and other statements
          <br />
          Provide information to employees on payroll matters, benefit plans and
          collective agreement provisions
          <br />
          Compile statistical reports, statements, and summaries related to pay
          and benefits accounts
          <br />
          Prepare and balance period-end reports and reconcile issued payrolls
          to bank statements
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay} />{" "}
        </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to jobbank.gc.ca, you can earn a median annual wage of $50k."
        }
        admission={"Diploma or Degree"}
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "payroll-specialist.parallax" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "payroll-specialist" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default PayrollPage
