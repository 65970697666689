import React from "react"

import QuestionCard from "../Question-Card/questionCard"

import styles from "./frequentlyAsked.module.scss"

const FrequentlyAsked = () => {
  return (
    <section className={styles.frequentlyAsked}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
        </div>
        <div className={styles.cardsWrapper}>
          <QuestionCard
            question="HOW TO APPLY?"
            answer="It’s easy! To start the application process, simply fill up above form!"
          />
          <QuestionCard
            question="CAN I COMPLETE MY PROGRAM ONLINE?"
            answer="TRILLIUM COLLEGE offers 100% online degree options in Arts, Business, Technology, Human Services. Check out our Programs to discover all the details."
          />
          <QuestionCard
            question="HOW LONG DOES IT TAKE TO COMPLETE A PROGRAM?"
            answer="Please visit program page to get more information."
          />
          <QuestionCard
            question="DO YOU OFFER FINANCIAL AID?"
            answer="Yes, we offer Grants, Loans and Private Student Loans.
Check for more info on our financial-aid page"
          />
        </div>
      </div>
    </section>
  )
}

export default FrequentlyAsked
