import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import styles from "./programCard.module.scss"

const CardProgram = ({ content, title, img }) => {

  let slug = title.split(' ').join('-').toLowerCase()
  if (title === 'supply chain management & logistic') slug = 'supply-chain-management-logistics'

  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { name: { regex: "/ico/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
                name
              }
            }
          }
        }
      `}
      render={data => (
        <div className={styles.cardWrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.headerWrapper}>
              <Link to={`/${slug}`}>
                <div className={styles.imageWrapper}>
                  {data.allFile.edges.map((ico, idx) => {
                    if (ico.node.name === img) {
                      return (
                        <Img
                          key={idx}
                          style={{ marginTop: "10px", minWidth: "55px" }}
                          imgStyle={{ margin: "0" }}
                          fluid={ico.node.childImageSharp.fluid}
                        />
                      )
                    }
                  })}
                </div>
                <div className={styles.titleWrapper}>
                  <h3>{title}</h3>
                </div>
              </Link>
            </div>
            <div className={styles.descriptionWrapper}><p>{content}</p></div>
          </div>
        </div>
      )}
    />
  )
}

export default CardProgram
