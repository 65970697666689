import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const MedicalOfficeAssistantPage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Become a Medical Office Assistant Specialist"
        titleOne="Medical Office Assistant Program"
      >
        Trillium College’s Medical Office Assistant diploma program prepares you to meet the demands of a career in medical administration. Our medical office curriculum provides you with the in-depth training in medical terminology, medical administrative procedures and the medical transcription skills needed to effectively support our communities’ medical professionals. Emphasis in this medical office program is placed on professional conduct, ethics, and standards within healthcare environments.
        As the population of senior citizens increases, the medical field has experienced rapid growth, providing vast opportunities in all aspects of medical administration. The Medical Office Assistant program at Trillium College prepares students to be an integral part of a patient focused team environment.

      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="This medical assisting program prepares graduates for work in medical administration with training in the following subject areas:"
      >
        <p>
          Medical Office Operations<br />
          Medical Terminology <br />
          Medical Transcription and Documentation<br />
          Word Processing and Spreadsheets <br />
          Infection Prevention and Control <br />
          First Aid and CPR <br />
          Ethics and Professionalism <br />
          Communication Skills <br />
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
      >
        <p>
          As a graduate of Trillium College’s Medical Office Assistant program, you may find employment as a medical assistant in a range of medical administrative settings. Graduates from our Medical Office Assistant program may find work at doctors’ and specialists’ offices, hospitals, clinics, wellness centres, dental environments, and long-term care facilities. Other employment opportunities as a medical assistant include employment with insurance agencies, pharmaceutical companies, medical sales organizations and government facilities.
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay} />{" "}
        </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to updated information on Indeed.ca"
        }
        admission={"Ontario Secondary School Diploma or Mature Student Status"}
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "payroll-specialist.parallax" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "Medical-Office-Assistant" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default MedicalOfficeAssistantPage
