import React from "react"
import { graphql } from "gatsby"
import { navigate } from "@reach/router"
import styles from "./notfound.module.scss"

const NotFound = ({ data }) => {
  return (
    <div className={styles.pageWrapper}>
      <img
        src={data.file.childImageSharp.sizes.base64}
        className={styles.logo}
      />
      <div className={styles.contentWrapper}>
        <h1>Page Not Found</h1>
        <button onClick={() => navigate(-1)}> Go back</button>
      </div>
    </div>
  )
}

export const query = graphql`
  {
    file(name: { eq: "logo.header" }) {
      childImageSharp {
        sizes(base64Width: 334, quality: 100) {
          originalName
          base64
        }
      }
    }
  }
`

export default NotFound
