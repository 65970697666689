import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import styles from "./game.module.scss"

import Layout from "../components/Layout/layout"
import AboutProgram from "../components/AboutProgram/aboutProgram"
import ProgramDescription from "../components/Program-Description/programDescription"
import BlueDescription from "../components/Blue-Description/blueDescription"

const HumanResourcePage = ({ data: { parallax, header } }) => (
  <div>
    <Layout hero={header}>
      <AboutProgram
        titleTwo="Human Resource Management"
        titleOne="Human Resource Management Graduate Program"
      >
        The purpose of Human Resource Management is to maximize the productivity
        of an organization by optimizing the effectiveness of its employees. HR
        Managers are engaged not only in securing and developing the talents of
        individual workers, but also in implementing programs that enhance
        communication and cooperation needed to nurture the development of an
        organization.
        <br /> Human Resource Management functions are positioned near the
        theoretic center of the organization, with access to all areas of the
        business.
      </AboutProgram>
      <ProgramDescription
        title="Areas of Focus"
        subtitle="This program covers information in the following subject areas:"
      >
        <p>
          Human Resource Administration
          <br />
          Training and Development
          <br />
          Fundamentals of Occupational Health and Safety
          <br />
          Organizational Behaviour
          <br />
          Recruitment and Selection
          <br />
          Human Resource Finance and Accounting
          <br />
          Human Resource Planning
          <br />
          Industrial Relations & Collective Bargaining
          <br />
          Compensation Management
        </p>
      </ProgramDescription>
      <ProgramDescription
        title="Employment Opportunities"
        subtitle={`As a graduate of the HRM program, you will find employment opportunities with a wide range of employers such as:`}
        endContent="Human resources are the most important assets of an organization; a business cannot be successful without effectively managing this resource; a philosophy that is unlikely to change in any fundamental way in the foreseeable future."
      >
        <p>
          Small Business
          <br />
          Government
          <br />
          Education
          <br />
          Retail
          <br />
          Hospitality
          <br />
          Manufacturing
          <br />
          National/International/Global Corporations
        </p>
      </ProgramDescription>
      <div className={styles.wrapper}>
        <BackgroundImage
          fluid={parallax.childImageSharp.fluid}
          className={styles.programPageParralax}
          style={{
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay} />{" "}
        </BackgroundImage>
      </div>
      <BlueDescription
        salary={
          "According to jobbank.gc.ca, you can earn a median salary of $32.95/hr as a Human Resource Manager (NOC 0112)."
        }
        admission={"Diploma or Degree from relevant business program"}
        page={false}
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    parallax: file(name: { eq: "human-resource-management.parallax" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    header: file(name: { eq: "human-resource-management" }) {
      childImageSharp {
        base64: sizes(base64Width: 1500, quality: 100) {
          base64
        }
      }
      name
    }
  }
`

export default HumanResourcePage
