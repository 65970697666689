import React from "react"

import Layout from "../components/Layout/layout"
import styles from '../components/AboutProgram/aboutProgram.module.scss'

const FinancialAid = () => (
  <div>
    <Layout>
    <section className={styles.sectionWrapper}>
      <div className={styles.aboutWrapper}>
        <h3>WE WILL FIND BEST FINANCIAL SOLUTION FOR YOU!</h3>
      </div>
    </section>
    </Layout>
  </div>
)

export default FinancialAid
